import Vue from 'vue'
import App from './App'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/bootstrap.min.css'
import './styles/css_common.css'
import './assets/js/bootstrap.min.js'
import './styles/common.css'
import './assets/js/bootstrap-paginator.js'
import 'jquery'
import axiosInstance from './services/axios'
import api from './services/api'
import store from './store/store'
import { Util } from './utils/util'
import {
	Form,
	FormItem,
	Input,
	Autocomplete,
	Select,
	Option,
	Checkbox,
	Pagination,
	Loading,
	Skeleton,
	SkeletonItem,
	Dialog,
	Button,
	Divider,
	Tabs,
	TabPane,
	Popover,
  Radio,
  Col,
  Row,
  Message,
  MessageBox,
  Image,
  Progress,
  RadioGroup,
  Table,
  TableColumn,
} from 'element-ui'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(RadioGroup)
Vue.use(Progress)
Vue.use(Image)
Vue.use(Col)
Vue.use(Row)
Vue.use(Button)
Vue.use(Divider)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Autocomplete)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(Pagination)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Loading.directive)
Vue.use(Popover)
Vue.use(Radio)
Vue.prototype.$loading = Loading.service
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message
Vue.prototype.$axios = axiosInstance
Vue.prototype.$api = api
Vue.prototype.$util = Util

import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)
import VTrack from 'v-track'
import trackEvents from '@/services/track-events'
Vue.use(VTrack, {
  trackEvents, // 埋点事件对象
  trackEnable: {
    UVPV: true,
    TONP: true
  }
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

if (module.hot) {
	module.hot.accept()
}
